import { createRouter, createWebHashHistory } from 'vue-router'
// import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    meta:{
      title:"你拖我拉"
    }
  },
  {
    path:'/about',
    name:'About',
    component: () => import('../views/about.vue'),
    meta:{
      title:"关于我们"
    }
  },
  {
    path:'/product',
    name:'Product',
    component: () => import('../views/product.vue'),
    meta:{
      title:"产品介绍"
    }
  },
  {
    path:'/firm',
    name:'Firm',
    component: () => import('../views/firm.vue'),
    meta:{
      title:"企业文化"
    }
  },
  {
    path:'/contact',
    name:'Contact',
    component: () => import('../views/contact.vue'),
    meta:{
      title:"联系我们"
    }
  },
  {
    path:'/login',
    name:'Login',
    component: () => import('../views/login.vue'),
    meta:{
      title:"登录"
    }
  },
  {
    path:'/sign',
    name:'Sign',
    component: () => import('../views/sign.vue'),
    meta:{
      title:"注册"
    }
  },
  {
    path:'/agreement',
    name:'Agreement',
    component: () => import('../views/agreement.vue'),
    meta:{
      title:"你拖我拉服务政策"
    }
  },
  {
    path:'/policy',
    name:'Policy',
    component: () => import('../views/policy.vue'),
    meta:{
      title:"你拖我拉协议"
    }
  },
  
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

router.beforeEach((to,from) => {
  if(to.meta.title) {
    document.title = to.meta.title
  }
})

export default router


