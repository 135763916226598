<template>
  <div class="navbox">
      <div class="nav">
        <img class="logo" src="./assets/img/Logo.png" alt="">
        <ul class="boxul">
          <router-link to="/">
            <li class="suspend">
            首页
            <span class="xian"></span>
          </li>
          </router-link>
          <router-link to="/about">
            <li class="suspend">
            关于我们
            <span class="xian"></span>
          </li>
          </router-link>
          <router-link to="/product">
            <li class="suspend">
            产品介绍
            <span class="xian"></span>
          </li>
          </router-link>
          <router-link to="/firm">
            <li class="suspend">
            企业文化
            <span class="xian"></span>
          </li>
          </router-link>
          <router-link to="/contact">
            <li class="suspend">
            联系我们
          </li>
          </router-link>
        </ul>
        <div class="navRight">
          <router-link class="control console" to="" v-if="islogin == 1">
            <div class="zhi">
              <img class="controlImg" src="./assets/img/kong1.png" alt="">
              <span class="kong" @click="dispatch">控制台</span>
            </div>
           <a @click="logout" class="out">安全退出</a>
          </router-link>
          <router-link class="login" to="/login" v-if="islogin == 0" @click="log = 1" :class="{ action : log === 1}">登录</router-link>
          <router-link class="enroll" to="/sign" v-if="islogin == 0" @click="log = 2" :class="{ action : log === 2}">免费注册</router-link>
        </div>
    </div>
  </div>

  <div class="box">
    
  <router-view />
  </div>

  <div class="bottomBox">
      <div class="bottomMain">
          <!-- 关于我们 -->
          <div class="about">
            <h3 class="aboutNav">关于我们</h3>
            <p class="aboutTitle">公司简介</p>
            <p class="aboutMain">公司大事件</p>
          </div>
          <!-- 服务项目 -->
          <div class="serve">
            <h3 class="aboutNav">服务项目</h3>
            <ul>
              <li class="aboutli" v-for="(item,index) in serveList" :key="index">
                <a class="serveTitle" href="">{{item.name}}</a>
              </li>
            </ul>
          </div>

        <!-- 联系我们 -->
        <div class="telBox">
          <h3 class="aboutNav">联系我们</h3>
          <div class="emile">
            <span class="emileTitle">公司邮箱</span>
            <span class="emileMain">service@nituowola.com</span>
          </div>
          <div class="site">
            <span class="siteTitle">公司地址</span>
            <span class="siteMain">宁波市鄞州区国贸大厦7楼</span>
          </div>
        </div>
        <!-- 底部右边 -->
        <div class="bottomRight">
          <img class="bottomLogo" src="./assets/img/Group4238.png" alt="">
          <div class="emile">
            <a class="emileTitle" href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">浙ICP备 2022002023号</a>
          </div>
        </div>
      </div>  
  </div>
</template>
<script>
  import Cookies from 'js-cookie';
  export default {
     data() {
      return {
        imgSrc:require("./assets/logo.png"),
        // add:0,
        islogin:0,
        log:2,
        serveList:[
        {
          name:'拖车'
        },
        {
          name:'轿车托运'
        },
        {
          name:'搭电'
        },
        {
          name:'换胎'
        },
        {
          name:'快修'
        },
        {
          name:'送油'
        },
        {
          name:'地库'
        },
        {
          name:'困境'
        },
        ]
      };
    },
    methods:{
      logout(){
        Cookies.set('ntwl-token', '',{domain: 'nituowola.com'});
        Cookies.set('ntwl-uid', '',{domain: 'nituowola.com'});
        Cookies.set('ntwl-authkey', '',{domain: 'nituowola.com'});
        location.reload();
      },
      dispatch() {
        window.location.href="https://dispatch.nituowola.com";
      }
    },
    created(){
      let token = Cookies.get('ntwl-token');
      if(token != '' && token != undefined){
        //alert(token);
        this.islogin = 1;
      }
    },
  }
</script>

<style lang="less">
.imgStyle{
  width: 200px;
  height: 200px;
  border: 2px solid red;
  margin-top: 300px;
  margin-left: 200px;

}
.uploadImg{
  width: 100px;
  height: 100px;
  background: #2C68FF;
}
  .box{
    width: 1440px;
    // height: 900px;
    // border: 1px solid black;
    margin: 60px auto 0px;
    position: relative;
  }
  .navbox{
    width: 100%;
    background-color: #fff;
    position: fixed;
    top: 0;
    z-index: 10;
  }
  // 顶部导航
  .nav{
    display: flex;
    width: 1440px;
    height: 60px;
    margin: 0 auto;
    background: rgba(255, 255, 255, 0.7);
    // background-color: pink;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    position: relative;
  }
  .logo{
    width: 135px;
    height: 43px;
    display: inline-block;
    margin-left: 72px;
    margin-top: 10px;

  }
  .boxul{
    display: flex;
  }
  .boxul li{
    line-height: 60px;
    font-size: 16px;
  }
  .boxul li{
    color: #000;
    font-weight: bold;
  }
  .xian{
    display: inline-block;
    width: 1px;
    height: 12px;
    background-color: #C4C4C4;
    margin: 0 25px;
  }
  .control{
    font-size: 16px;
    line-height: 60px;
    margin-right: 30px;
    // float:right;
    // margin-right: 60px;
  }
  .controlImg{
    width: 14px;
    height: 14px;
    margin-right: 8px;
    vertical-align: middle;
  }
  .login{
    width: 106px;
    height: 42px;
    color: #000;
    margin-right: 10px;
    margin-top: 10px;
    line-height: 42px;
    text-align: center;
    border-radius: 8px;
    background-color: #E9EFFF;
  }
  // .login:hover{
  //   background-color: #D5E1FF;
  // }
  .enroll{
    width: 106px;
    height: 42px;
    // background-color: #2c68ff;
    background: rgba(44, 104, 255, 0.2);
    // color: #fff;
    color: #000;
    // font-size: 14px;
    text-align: center;
    line-height: 42px;
    margin-right: 40px;
    border-radius: 8px;
    margin-top: 10px;
  }
// .enroll:hover{
//   background-color: #2659D9;
// }
  .navRight{
    display: flex;
    position: absolute;
    right: 0;
  }

  // 底部
  .bottomBox{
    width: 100%;
    height: 332px;
    background-color: #171717;
    
  }
  .bottomMain{
    width: 1440px;
    height: 100%;
    margin: 0 auto;
    position: relative;
  }
  .about{
    float: left;
    width: 80px;
    height: 106px;
    padding-top: 30px;
    margin-left: 72px;
    box-sizing: border-box;
  }
  .aboutNav{
    font-size: 16px;
    color: #fff;
  }
  .serve ul{
    width: 140px;
    margin-top: 10px;
  }
 .aboutli{
   margin-top: 10px;
   margin-right: 20px;
   float: left;
 }



  .aboutTitle,.aboutMain{
    font-size: 16px;
    color: rgba(255, 255, 255, 0.45);
    margin-top: 20px;
  }
  .serve{
    width: 100px;
    height: 224px;
    display: inline-block;
    margin-top: 30px;
    margin-left: 116px;
    vertical-align: top;
  }
  .serveTitle{
    color: rgba(255, 255, 255, 0.45);
    font-size: 16px;
  }
  .telBox{
    width: 369px;
    height: 123px;
    display: inline-block;
    margin-top: 30px;
    margin-left: 137px;
  }
  .emile{
    margin-top: 25px;
  }
  .emileTitle,.emileMain,.siteTitle,.siteMain{
    font-size: 16px;
    color: rgba(255, 255, 255, 0.45);
  }
  .emileTitle,.siteTitle{
    margin-right: 10px;
     color: rgba(255, 255, 255, 0.45);
  }
  .site{
    margin-top: 10px;
  }
  .siteMain{
    width: 295px;
    display: inline-block;
    vertical-align: top;
  }
  .bottomRight{
    position: absolute;
    top: 45px;
    right: 113px;
  }
  .bottomLogo{
    width: 139px;
    height: 30px;
  }
.router-link-exact-active>li {
  color: #2c68ff ;
}
.suspend:hover{
  color: #2c68ff;
}
.action{
  color: #fff;
  background-color: #2c68ff;
}
.out{
  font-size: 15px;
  width: 106px;
  height: 42px;
  line-height: 42px;
  display: inline-block;
  background-color: #2c68ff;
  margin-top: 10px;
  margin-right: 30px;
  text-align: center;
  border-radius: 8px;
  color: #fff;
  cursor: pointer;
}
.out:hover{
  background-color: #2659D9;
}
.console{
  // width: 106px;
  height: 42px;
  color: #000;
  font-size: 15px;
  margin-right: 20px;
  // margin-top: 10px;
  line-height: 42px;
  text-align: center;
  // border-radius: 8px;
  // background-color: #E9EFFF;
  margin-left: 30px;
}

.zhi{
  display: inline-block;
  width: 106px;
  height: 42px;
  display: inline-block;
  background-color: #E9EFFF;
  border-radius: 8px;
  margin-right: 20px;
}
.zhi:hover{
  background-color: #D5E1FF;
}
</style>
